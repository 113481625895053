import { List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";

const FormReview = ({ formValues, handleFormChange }) => {
  const {
    nombre,
    cedula,
    direccion,
    telefonoCasa,
    telefonoCelular,
    empresaNombre,
    empresaDireccion,
    empresaTipo,
    ocupacion,
    salario,
    otrosIngresos,
    nombreFamiliar,
    telefonoFamiliar,
    tipoVivienda,
    nombreProducto,
    tiempoVivienda,
    montoFinanciar,
    mesesFinanciar,
    montoInicial,
    emailCliente,
    sucursal,
  } = formValues;

  return (
    <>
      <Typography sx={{ textAlign: "left", marginLeft: 2, marginTop: 3 }} variant="h6" gutterBottom>
        Información de Solicitud de Préstamo
      </Typography>
      <List disablePadding>
        <ListItem>
          <ListItemText primary="Nombre completo" secondary={nombre} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Cedula" secondary={cedula} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Dirección" secondary={direccion} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Teléfono casa" secondary={telefonoCasa} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Teléfono celular" secondary={telefonoCelular} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Email Cliente" secondary={emailCliente} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Nombre Producto" secondary={nombreProducto} />
        </ListItem>
      </List>

      <Typography sx={{ textAlign: "left", marginLeft: 2 }} variant="h6" gutterBottom>
        Información Laboral
      </Typography>
      <List disablePadding>
        <ListItem>
          <ListItemText primary="Nombre de la empresa" secondary={empresaNombre} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Dirección de la empresa" secondary={empresaDireccion} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Tipo de empresa" secondary={empresaTipo} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Ocupación" secondary={ocupacion} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Salario" secondary={`$${salario}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Otros ingresos" secondary={`$${otrosIngresos}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Nombre de un familiar" secondary={nombreFamiliar} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Teléfono de un familiar" secondary={telefonoFamiliar} />
        </ListItem>
      </List>

      <Typography variant="h6" sx={{ textAlign: "left", marginLeft: 2 }} gutterBottom>
        Información Adicional
      </Typography>
      <List disablePadding>
        <ListItem>
          <ListItemText primary="Tipo de vivienda" secondary={tipoVivienda} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Tiempo viviendo en la dirección actual" secondary={tiempoVivienda} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Monto a financiar" secondary={`$${montoFinanciar}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Monto Inicial" secondary={montoInicial} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Meses para financiar" secondary={mesesFinanciar} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Sucursal a retirar equipo" secondary={sucursal} />
        </ListItem>
      </List>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default FormReview;
