import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Typography } from "@mui/material";
import logo from "../images/logo.png";
function Header() {
  return (
    <AppBar style={{ backgroundColor: "white" }}>
      <Toolbar>
        <Typography variant="h7" to="/" sx={{ color: "white" }}></Typography>

        <img style={{ width: 100, marginLeft: 5 }} src={logo} alt="Logo" />
        <span style={{ marginLeft: 50, color: "black", fontWeight: "bold" }}>Formulario de Solicitud</span>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
