import React from "react";
import { FormControl, InputLabel, Select, MenuItem, Grid, Typography } from "@mui/material";

function TiempoLaborandoComponent({ formValues, handleFormChange }) {
  // Generar opciones para años y meses
  const yearOptions = [...Array(21).keys()]; // Genera un array de 0 a 20
  const monthOptions = [...Array(13).keys()]; // Genera un array de 0 a 12

  return (
    <div>
      <Typography variant="h6" sx={{ marginTop: 3 }}>
        Tiempo Laborando
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Años</InputLabel>
            <Select
              name="tiempoEmpresaAnos"
              value={formValues.tiempoEmpresaAnos}
              onChange={handleFormChange}
              label="Años"
              required
            >
              {yearOptions.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Meses</InputLabel>
            <Select
              name="tiempoEmpresaMeses"
              value={formValues.tiempoEmpresaMeses}
              onChange={handleFormChange}
              label="Meses"
              required
            >
              {monthOptions.map((month) => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}

export default TiempoLaborandoComponent;
