/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import { Button, Step, StepLabel, Stepper } from "@mui/material";
import Cliente from "./cliente";
import axios from "axios";
import FormReview from "./review";
import Prestamo from "./prestamo";
import Referencia from "./referencia";
import "../styles/home.css";
import { toast, Zoom } from "react-toastify";
import { Box, LinearProgress, Switch, TextareaAutosize } from "@mui/material";

function Home() {
  const [activeStep, setActiveStep] = useState(0);
  const [progressBarOff, setprogressBarOff] = useState({
    display: "none",
  });
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [formValues, setFormValues] = useState({
    nombre: "",
    cedula: "",
    direccion: "",
    telefonoCasa: " ",
    telefonoCelular: "",
    empresaNombre: "",
    empresaDireccion: "",
    empresaTipo: "",
    ocupacion: "",
    salario: "",
    otrosIngresos: " ",
    nombreFamiliar: "",
    telefonoFamiliar: "",
    tipoVivienda: "",
    tiempoVivienda: "",
    tiempoEmpresa: "",
    montoFinanciar: "",
    montoInicial: " ",
    mesesFinanciar: "6",
    cedulaImage: " ",
    equipoImage: " ",
    emailCliente: " ",
    sucursal: "",
  });
  const [formTouched, setFormTouched] = useState({
    nombre: "",
    cedula: "",
    direccion: "",
    telefonoCasa: " ",
    telefonoCelular: "",
    empresaNombre: "",
    empresaDireccion: "",
    empresaTipo: "",
    ocupacion: "",
    salario: "",
    otrosIngresos: "",
    nombreFamiliar: "",
    telefonoFamiliar: "",
    tipoVivienda: "",
    tiempoVivienda: "",
    tiempoEmpresa: "",
    montoFinanciar: "",
    montoInicial: " ",
    mesesFinanciar: "",
    cedulaImage: " ",
    equipoImage: " ",
    emailCliente: "",
    sucursal: "",
  });
  const [formErrors, setFormErrors] = useState({
    nombre: "",
    cedula: "",
    direccion: "",
    telefonoCasa: " ",
    telefonoCelular: "",
    empresaNombre: "",
    empresaDireccion: "",
    empresaTipo: "",
    ocupacion: "",
    salario: "",
    otrosIngresos: "",
    nombreFamiliar: "",
    telefonoFamiliar: "",
    tipoVivienda: "",
    tiempoVivienda: "",
    tiempoEmpresa: "",
    montoFinanciar: "",
    montoInicial: " ",
    mesesFinanciar: "6",
    cedulaImage: " ",
    equipoImage: " ",
    emailCliente: "",
    sucursal: "",
  });

  const cleanUpFormValues = () => {
    setFormValues({
      nombre: "",
      cedula: "",
      direccion: "",
      telefonoCasa: " ",
      telefonoCelular: "",
      empresaNombre: "",
      empresaDireccion: "",
      empresaTipo: "",
      ocupacion: "",
      salario: "",
      otrosIngresos: " ",
      nombreFamiliar: "",
      telefonoFamiliar: "",
      tipoVivienda: "",
      tiempoVivienda: "",
      tiempoEmpresa: "",
      montoFinanciar: "",
      montoInicial: " ",
      mesesFinanciar: "",
      cedulaImage: " ",
      nombreProducto: " ",
      equipoImage: " ",
      emailCliente: "",
      sucursal: "",
    });
  };

  const validaCedula = async () => {
    // fetch("https://api.adamix.net/apec/cedula/" + formValues.cedula)
    const cedulaEsValida = await fetch(`https://api.digital.gob.do/v3/cedulas/${formValues.cedula}/validate`);
    return cedulaEsValida.ok;
  };

  const handleNext = async (event) => {
    const cedulaEsValida = await validaCedula();
    if (!cedulaEsValida) {
      alert("Esta cedula no es valida. Por favor, verifique que la cedula sea correcta y vuelva a intentarlo.");
      return;
    }
    event.preventDefault();

    let formValuesCleaned = formValues;

    // if (formValuesCleaned.cedulaImage.length < 5) {
    //   alert("Debe Adjuntar la cédula de Identidad. y Completar los campos en rojo.");
    //   return;
    // }
    // if (formValuesCleaned.equipoImage.length < 5) {
    //   alert("Debe Adjuntar el equipo que desea financiar. y Completar los campos en rojo.");
    //   return;
    // }
    // valida sucursal
    if (formValuesCleaned.sucursal === "") {
      alert("Debe seleccionar una sucursal.");
      return;
    }
    // Check for errors
    if (activeStep === 0) {
      delete formValuesCleaned.telefonoFamiliar;
      delete formValuesCleaned.nombreFamiliar;
      delete formValuesCleaned.montoFinanciar;
      if (formValues.telefonoCasa.length < 2) {
        formValues.telefonoCasa = " ";
      }
      delete formValuesCleaned.montoInicial;
      // delete formValuesCleaned.tipoVivienda;
    }
    if (activeStep === 1) {
      formValuesCleaned = {
        montoFinanciar: formValues.montoFinanciar,
        mesesFinanciar: formValues.mesesFinanciar,
        montoInicial: formValues?.montoInicial || " ",
      };
    }
    if (activeStep === 2) {
      formValuesCleaned = { nombreFamiliar: formValues.nombreFamiliar, telefonoFamiliar: formValues.telefonoFamiliar };
    }
    if (activeStep === 3) {
      try {
        sendEmail();
      } catch (error) {}

      //  setActiveStep(0);
      return;
    }
    const errors = validate(formValuesCleaned);
    console.log(errors);
    setFormErrors(errors);

    // Prevent submission if there are errors
    if (Object.keys(errors).length > 0) {
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const validate = (values) => {
    const errors = {};
    // Check for blank fields
    Object.keys(values).forEach((key) => {
      if (!values[key]) {
        errors[key] = "This field is required";
      }
    });

    return errors;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    setFormTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <Cliente formValues={formValues} handleFormChange={handleFormChange} />;
      case 1:
        return <Prestamo formValues={formValues} handleFormChange={handleFormChange} />;
      case 2:
        return <Referencia formValues={formValues} handleFormChange={handleFormChange} />;
      case 3:
        return <FormReview formValues={formValues} handleFormChange={handleFormChange} />;
      default:
        return "Unknown stepIndex";
    }
  };

  const sendEmail = () => {
    setIsSendingEmail(true);
    const username = process.env.REACT_APP_SENDGRID_API_USER;
    const password = process.env.REACT_APP_SENDGRID_API_PASSWORD;
    const url = process.env.REACT_APP_SENDGRID_API_URL;
    const payload = {
      nombre: formValues.nombre,
      cedula: formValues.cedula,
      direccion: formValues.direccion,
      telefonoCasa: formValues.telefonoCasa,
      telefonoCelular: formValues.telefonoCelular,
      empresaNombre: formValues.empresaNombre,
      empresaDireccion: formValues.empresaDireccion,
      empresaTipo: formValues.empresaTipo,
      ocupacion: formValues.ocupacion,
      salario: formValues.salario,
      otrosIngresos: formValues.otrosIngresos,
      nombreFamiliar: formValues.nombreFamiliar,
      telefonoFamiliar: formValues.telefonoFamiliar,
      tipoVivienda: formValues.tipoVivienda,
      tiempoVivienda: formValues.tiempoVivienda,
      tiempoEmpresa: formValues.tiempoEmpresa,
      montoFinanciar: formValues.montoFinanciar,
      montoInicial: formValues.montoInicial,
      mesesFinanciar: formValues.mesesFinanciar,
      nombreProducto: formValues.nombreProducto,
      cedulaImage: formValues.cedulaImage,
      equipoImage: formValues.equipoImage,
      emailCliente: formValues.emailCliente,
      sucursal: formValues.sucursal,
    };
    const headers = {
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
      "Content-Type": "application/json",
    };
    setprogressBarOff({
      display: "block",
    });

    axios
      .post(url, payload, { headers })
      .then((response) => {
        console.log(response.data);
        setprogressBarOff({
          display: "none",
        });
        alert(
          "Su solicitud ha sido enviada correctamente. El Banco se pondrá en contacto " +
            "con usted en un plazo de 24 a 48 horas para responder a su solicitud. Por favor, " +
            "manténgase pendiente de los números de contacto que proporcionó en la solicitud."
        );
        setIsSendingEmail(false);
        cleanUpFormValues();

        location.reload();
      })
      .catch((error) => {
        setIsSendingEmail(false);
        setprogressBarOff({
          display: "none",
        });
        console.error(error);
        alert(error);
      });
  };
  const styleProgressBar = () => {
    let changedFieldColor = {
      display: "block",
    };
    changedFieldColor = {
      display: "none",
    };
    return changedFieldColor;
  };

  return (
    <div>
      <div style={{ overflow: "auto" }}>
        <Stepper activeStep={activeStep} sx={{ marginTop: 12 }}>
          <Step>
            <StepLabel>Cliente</StepLabel>
          </Step>
          <Step>
            <StepLabel>Prestamo</StepLabel>
          </Step>
          <Step>
            <StepLabel>Referencias</StepLabel>
          </Step>
        </Stepper>

        <div style={progressBarOff}>
          <h3>Enviando Solicitud...</h3>
          <LinearProgress value={"progress"} color="warning" />
        </div>
        {getStepContent(activeStep)}
      </div>
      <div style={{ position: "fixed", bottom: 0, left: 0, width: "100%", textAlign: "right", zIndex: 999 }}>
        <div style={{ backgroundColor: "#f5f5f5", padding: "20px" }}>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Atras
          </Button>
          <Button disabled={isSendingEmail} variant="contained" color="primary" onClick={handleNext}>
            {activeStep === 3 ? "Enviar" : activeStep === 2 ? "Revisar" : "Siguiente"}
          </Button>
          <br />
        </div>
      </div>
    </div>
  );
}
export default Home;
