import React, { useState } from "react";
import { Button, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";

function Prestamo({ formValues, handleFormChange }) {
  const [mesesFinanciar, setMesesFinanciar] = useState(formValues.mesesFinanciar);
  const [montoInicialCheck, setmontoInicialCheck] = useState(false);

  const handleMesesChange = (e) => {
    formValues.mesesFinanciar = e.target.value;
    setMesesFinanciar(e.target.value);
    console.log(formValues);
  };

  const handleCheckMontoInicial = (e) => {
    if (!e) {
      formValues.montoInicial = "";
    }
    setmontoInicialCheck(!e);
  };

  return (
    <div style={{ margin: 15 }}>
      <TextField
        sx={{ marginTop: 3 }}
        label="Monto a Financiar"
        name="montoFinanciar"
        variant="standard"
        type="number"
        inputProps={{ pattern: "[0-9]*" }}
        value={formValues.montoFinanciar || " "}
        onChange={handleFormChange}
        fullWidth
        required
        error={!formValues.montoFinanciar}
        helperText={formValues?.montoFinanciar === "" ? "Debe llenar este campo" : null}
      />
      <span hidden={!montoInicialCheck}>
        {" "}
        <TextField
          sx={{ marginTop: 3 }}
          label="Monto Inicial"
          name="montoInicial"
          type="number"
          inputProps={{ pattern: "[0-9]*" }}
          variant="standard"
          value={formValues.montoInicial}
          onChange={handleFormChange}
          fullWidth
          hidden={true}
        />
      </span>
      <span>
        <Button sx={{ mt: 3 }} style={{ color: "black" }} onClick={() => handleCheckMontoInicial(montoInicialCheck)}>
          Desea dar un Monto Inicial
          <Switch color="success" defaultChecked={false} />
        </Button>
      </span>

      {/* <TextField
        sx={{ marginTop: 2 }}
        label="Meses Financiar"
        name="mesesFinanciar"
        variant="standard"
        value={formValues.mesesFinanciar}
        onChange={handleFormChange}
        fullWidth
        required
        error={!formValues.mesesFinanciar}
        helperText={formValues.mesesFinanciar === "" ? "Debe llenar este campo" : null}
      /> */}

      <div>
        <InputLabel sx={{ marginTop: 3 }} id="mesesFinanciar">
          Meses Financiar
        </InputLabel>
        <Select
          sx={{ minWidth: "100%" }}
          labelId="mesesFinanciar"
          id="mesesFinanciar"
          value={mesesFinanciar}
          label="mesesFinanciar"
          onChange={handleMesesChange}
          required
        >
          <MenuItem value={"6"}>6</MenuItem>
          <MenuItem value={"12"}>12</MenuItem>
          <MenuItem value={"18"}>18</MenuItem>
          <MenuItem value={"24"}>24</MenuItem>
          <MenuItem value={"36"}>36</MenuItem>
        </Select>
      </div>
    </div>
  );
}

export default Prestamo;
