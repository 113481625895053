import React, { useEffect, useState } from "react";
import { Button, InputLabel, MenuItem, Select } from "@mui/material";
import { Error } from "@mui/icons-material";
import axios from "axios";
import { toast, Zoom } from "react-toastify";
import InputMask from "react-input-mask";
import { TextField, Grid, Typography } from "@mui/material";
import TiempoLaborandoComponent from "./tiempoEmpresa";
import ReCAPTCHA from "react-google-recaptcha";

function PhoneInput(props) {
  return (
    <InputMask
      mask="999-999-9999"
      type="number"
      inputProps={{ pattern: "[0-9]*" }}
      value={props.value}
      onChange={props.onChange}
    >
      {() => (
        <TextField
          sx={{ marginTop: 3 }}
          label="Telefono Celular"
          name="telefonoCelular"
          variant="standard"
          fullWidth
          inputProps={{ pattern: "[0-9]*" }}
          required
          error={!props.value}
          helperText="Enter a valid phone number"
          value={props.value}
          onChange={props.onChange}
        />
      )}
    </InputMask>
  );
}
function TelResidencialInput(props) {
  return (
    <InputMask mask="999-999-9999" value={props.value} onChange={props.onChange}>
      {() => (
        <TextField
          sx={{ marginTop: 3 }}
          label="Telefono Residencial"
          name="telefonoCasa"
          variant="standard"
          fullWidth
          value={props.value}
          onChange={props.onChange}
        />
      )}
    </InputMask>
  );
}
function CedulaInput(props) {
  return (
    <InputMask mask="99999999999" value={props.value} inputProps={{ pattern: "[0-9]*" }} onChange={props.onChange}>
      {() => (
        <TextField
          sx={{ marginTop: 3 }}
          label="Cedula de Identidad"
          name="cedula"
          required
          error={!props.value}
          helperText="Entre una Cedula de Identidad valida"
          variant="standard"
          fullWidth
          value={props.value}
          onChange={props.onChange}
        />
      )}
    </InputMask>
  );
}

const Cliente = ({ formValues, handleFormChange }) => {
  const [tipoVivienda, setTipoVivienda] = useState(formValues.tipoVivienda);
  const [empresaTipo, setEmpresaTipo] = useState(formValues.empresaTipo);
  const [tiempoEmpresaAnos, setTiempoEmpresaAnos] = useState(0);
  const [tiempoEmpresaMeses, setTiempoEmpresaMeses] = useState(0);
  const [productos, setProductos] = useState([]);
  const [selectedProducto, setSelectedProducto] = useState("");
  const [formErrors, setFormErrors] = useState({
    nombre: "",
    cedula: "",
    direccion: "",
    telefonoCasa: " ",
    telefonoCelular: "",
    empresaNombre: "",
    empresaDireccion: "",
    empresaTipo: "",
    tiempoEmpresa: "",
    ocupacion: "",
    salario: "",
    otrosIngresos: "",
    nombreFamiliar: "",
    telefonoFamiliar: "",
    tipoVivienda: "Propia",
    tiempoVivienda: "",
    montoFinanciar: "",
    mesesFinanciar: "",
    montoInicial: "",
    cedulaImage: " ",
    equipoImage: " ",
    nombreProducto: " ",
    emailCliente: "",
    sucursal: "",
    tiempoEmpresaAnos: "",
    tiempoEmpresaMeses: "",
  });
  const handleRecaptchaChange = (value) => {
    console.log("Captcha value:", value);
    // Here you can handle the verification value (a user response token) you get from reCAPTCHA
  };

  useEffect(() => {
    getProductos();
  }, []);

  const [formTouched, setFormTouched] = useState({
    nombre: false,
    cedula: false,
    direccion: false,
    telefonoCasa: false,
    telefonoCelular: false,
    empresaNombre: false,
    empresaDireccion: false,
    empresaTipo: false,
    ocupacion: false,
    salario: false,
    otrosIngresos: false,
    nombreFamiliar: false,
    telefonoFamiliar: false,
    tipoVivienda: false,
    tiempoVivienda: false,
    tiempoEmpresa: false,
    montoFinanciar: false,
    mesesFinanciar: false,
    montoInicial: false,
    cedulaImage: false,
    nombreProducto: false,
    equipoImage: false,
    emailCliente: "",
    sucursal: "",
  });

  const handleTipoViviendaChange = (e) => {
    formValues.tipoVivienda = e.target.value;
    setTipoVivienda(e.target.value);
  };
  const handleEmpresaTipoChange = (e) => {
    formValues.empresaTipo = e.target.value;
    setEmpresaTipo(e.target.value);
  };

  const sendEmail = () => {
    let t = "";
    console.log(process.env);

    const username = process.env.REACT_APP_SENDGRID_API_USER;
    const password = process.env.REACT_APP_SENDGRID_API_PASSWORD;
    const url = process.env.REACT_APP_SENDGRID_API_URL;
    const payload = {
      nombre: "John Dssoeff",
      cedula: "1234567890",
      direccion: "123 Main St",
      telefonoCasa: "555-1234",
      telefonoCelular: "555-5678",
      empresaNombre: "Acme Inc.",
      empresaDireccion: "456 Business Rd",
      empresaTipo: "Privada",
      ocupacion: "Desarrollador",
      salario: 50000,
      otrosIngresos: 10000,
      nombreFamiliar: "Jane Doe",
      telefonoFamiliar: "555-4321",
      tipoVivienda: "Propia",
      tiempoVivienda: "5 años",
      montoFinanciar: 100000,
      mesesFinanciar: 60,
      montoInicial: 0,
      emailCliente: "",
      sucursal: "",
    };
    const headers = {
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
      "Content-Type": "application/json",
    };

    axios
      .post(url, payload, { headers })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleProductoChange = (event) => {
    setSelectedProducto(event.target.value);
  };
  const getProductos = () => {
    const username = process.env.REACT_APP_SENDGRID_API_USER;
    const password = process.env.REACT_APP_SENDGRID_API_PASSWORD;
    const url = process.env.REACT_APP_SENDGRID_API;
    const payload = {};
    const headers = {
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
      "Content-Type": "application/json",
    };
    axios
      .get(url + "/getProductosAllTech", { headers })
      .then((response) => {
        setProductos(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const changeCedulaHandler = async (event) => {
    const file = event.target.files[0];
    const base64 = await toBase64(file);
    formValues.cedulaImage = base64;
  };
  const changeEquipoHandler = async (event) => {
    const file = event.target.files[0];
    const base64 = await toBase64(file);
    formValues.equipoImage = base64;
  };

  const [sucursal, setSucursal] = useState("");
  const [cedula, setCedula] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneRes, setPhoneRes] = useState("");
  const handleInputCelular = ({ target: { value } }) => {
    setPhone(value);
    formValues.telefonoCelular = value;
  };
  const handleInputTelRes = ({ target: { value } }) => {
    setPhoneRes(value);
    formValues.telefonoCasa = value;
  };
  const handleInputCedula = ({ target: { value } }) => {
    setCedula(value);
    formValues.cedula = value;
  };
  const handleSucursalChange = (e) => {
    formValues.sucursal = e.target.value;
    setSucursal(e.target.value);
  };

  const handleTiempoLaborandoChange = (e) => {
    const { name, value } = e.target;
    const valor = parseInt(value, 10) || 0; // Asegúrate de convertir a número y manejar valores no numéricos.

    if (name === "tiempoEmpresaAnos") {
      setTiempoEmpresaAnos(valor); // Actualiza el estado de años directamente.
      // Llama a handleFormChange para actualizar tiempoEmpresa utilizando el valor actual o el más reciente para meses.
      handleFormChange({
        target: {
          name: "tiempoEmpresa",
          value: `Años: ${valor}, Meses: ${tiempoEmpresaMeses}`,
        },
      });
    } else if (name === "tiempoEmpresaMeses") {
      setTiempoEmpresaMeses(valor); // Actualiza el estado de meses directamente.
      // Llama a handleFormChange para actualizar tiempoEmpresa utilizando el valor actual o el más reciente para años.
      handleFormChange({
        target: {
          name: "tiempoEmpresa",
          value: `Años: ${tiempoEmpresaAnos}, Meses: ${valor}`,
        },
      });
    }
  };

  return (
    <div style={{ margin: 15 }}>
      {/* <ReCAPTCHA sitekey="Your-Site-Key-Here" onChange={handleRecaptchaChange} /> */}
      <TextField
        sx={{ marginTop: 3 }}
        label="Nombre y Apellido"
        name="nombre"
        variant="standard"
        value={formValues.nombre}
        onChange={handleFormChange}
        fullWidth
        required
        error={!formValues.nombre}
        helperText={formValues.nombre === "" ? "Debe llenar este campo" : null}
      />
      {/* <Button variant="contained" color="primary" sx={{ marginTop: 3 }} onClick={sendEmail}>
        TEST EMAIL
      </Button> */}
      {/* <TextField
        sx={{ marginTop: 3 }}
        label="cedula de Identidad"
        name="cedula"
        variant="standard"
        value={formValues.cedula}
        onChange={handleFormChange}
        fullWidth
        type="number"
        required
        error={!formValues.cedula}
        helperText={formValues.cedula === "" ? "Debe llenar este campo" : null}
      /> */}

      <CedulaInput
        error={!formValues.cedula}
        helperText={formValues.cedula === "" ? "Debe llenar este campo" : null}
        value={cedula}
        required
        onChange={handleInputCedula}
      />

      <div style={{ width: 1500 }}>
        <input onChange={changeCedulaHandler} type="file" name="file" />
      </div>
      <label>Adjunte su cedula de Identidad (Opcional)</label>

      <PhoneInput
        error={!formValues.telefonoCelular}
        helperText={formValues.telefonoCelular === "" ? "Debe llenar este campo" : null}
        value={phone}
        required
        onChange={handleInputCelular}
      />
      <TelResidencialInput value={phoneRes} onChange={handleInputTelRes} />

      <TextField
        sx={{ marginTop: 3 }}
        label="Direccion de Residencia"
        variant="standard"
        name="direccion"
        value={formValues.direccion}
        onChange={handleFormChange}
        type="text"
        fullWidth
        required
        error={!formValues.direccion}
        helperText={formValues.direccion === "" ? "Debe llenar este campo" : null}
      />
      <TextField
        sx={{ marginTop: 3 }}
        label="Su Correo Electronico"
        variant="standard"
        name="emailCliente"
        value={formValues.emailCliente}
        onChange={handleFormChange}
        type="text"
        fullWidth
      />
      <div>
        <InputLabel sx={{ marginTop: 3 }} id="tipoVivienda">
          Tipo de Vivienda
        </InputLabel>
        <Select
          sx={{ minWidth: "100%" }}
          labelId="tipoVivienda"
          id="tipoVivienda"
          value={tipoVivienda}
          label="Age"
          onChange={handleTipoViviendaChange}
        >
          <MenuItem value={"Propia"} selected={true}>
            Propia
          </MenuItem>
          <MenuItem value={"Alquilada"}>Alquilada</MenuItem>
        </Select>
        <label hidden={tipoVivienda.toString() !== ""} style={{ color: "#d32f2f" }}>
          Debe seleccionar este campo
        </label>
      </div>
      {/* 
      <TextField
        sx={{ marginTop: 3 }}
        label="Tipo de Vivienda"
        variant="standard"
        name="tipoVivienda"
        value={formValues.tipoVivienda}
        onChange={handleFormChange}
        type="text"
        fullWidth
        required
        error={!formValues.tipoVivienda}
        helperText={formValues.tipoVivienda === "" ? "Debe llenar este campo" : null}
      /> */}
      <TextField
        sx={{ marginTop: 3 }}
        label="Tiempo en Vivienda"
        variant="standard"
        name="tiempoVivienda"
        value={formValues.tiempoVivienda}
        onChange={handleFormChange}
        type="text"
        fullWidth
        required
        error={!formValues.tiempoVivienda}
        helperText={formValues.tiempoVivienda === "" ? "Debe llenar este campo" : null}
      />
      <TextField
        sx={{ marginTop: 3 }}
        label="Cual es su Ocupacion?"
        variant="standard"
        name="ocupacion"
        value={formValues.ocupacion}
        onChange={handleFormChange}
        type="text"
        fullWidth
        required
        error={!formValues.ocupacion}
        helperText={formValues.ocupacion === "" ? "Debe llenar este campo" : null}
      />
      <TextField
        sx={{ marginTop: 3 }}
        label="Empresa donde Labora"
        variant="standard"
        name="empresaNombre"
        value={formValues.empresaNombre}
        onChange={handleFormChange}
        type="text"
        fullWidth
        required
        error={!formValues.empresaNombre}
        helperText={formValues.empresaNombre === "" ? "Debe llenar este campo" : null}
      />
      <TextField
        sx={{ marginTop: 3 }}
        label="Salario"
        variant="standard"
        name="salario"
        value={formValues.salario}
        onChange={handleFormChange}
        type="number"
        inputProps={{ pattern: "[0-9]*" }}
        fullWidth
        required
        error={!formValues.salario}
        helperText={formValues.salario === "" ? "Debe llenar este campo" : null}
      />

      <TiempoLaborandoComponent formValues={formValues} handleFormChange={handleTiempoLaborandoChange} />
      <TextField
        sx={{ marginTop: 3 }}
        label=""
        variant="standard"
        name="tiempoEmpresa"
        disabled
        value={formValues.tiempoEmpresa}
        onChange={handleFormChange}
        type="text"
        fullWidth
        required
        error={!formValues.tiempoEmpresa}
        helperText={formValues.tiempoEmpresa === "" ? "Debe llenar este campo" : null}
      />
      <TextField
        sx={{ marginTop: 3 }}
        label="Otros Ingresos"
        variant="standard"
        name="otrosIngresos"
        value={formValues.otrosIngresos}
        onChange={handleFormChange}
        type="number"
        inputProps={{ pattern: "[0-9]*" }}
        fullWidth
        error={!formValues.otrosIngresos}
        helperText={formValues.otrosIngresos === "" ? "Debe llenar este campo" : null}
      />
      <TextField
        sx={{ marginTop: 3 }}
        label="Direccion de Empresa donde Labora"
        variant="standard"
        name="empresaDireccion"
        value={formValues.empresaDireccion}
        onChange={handleFormChange}
        type="text"
        fullWidth
        required
        error={!formValues.empresaDireccion}
        helperText={formValues.empresaDireccion === "" ? "Debe llenar este campo" : null}
      />

      <div>
        <InputLabel sx={{ marginTop: 3 }} id="empresaTipo">
          Tipo de Empresa donde Labora
        </InputLabel>
        <Select
          sx={{ minWidth: "100%" }}
          labelId="empresaTipo"
          id="empresaTipo"
          value={empresaTipo}
          label="Age"
          onChange={handleEmpresaTipoChange}
          required
        >
          <MenuItem value={"Publica"} selected={true}>
            Publica
          </MenuItem>
          <MenuItem value={"Privada"} selected>
            Privada
          </MenuItem>
          <MenuItem value={"NegacioPropio"}>Negocio Propio</MenuItem>
        </Select>
        <label hidden={empresaTipo.toString() !== ""} style={{ color: "#d32f2f" }}>
          Debe seleccionar este campo
        </label>

        <TextField
          sx={{ marginTop: 3 }}
          label="Descripcion del Equipo a financiar"
          variant="standard"
          name="nombreProducto"
          value={formValues.nombreProducto}
          onChange={handleFormChange}
          type="text"
          fullWidth
          error={!formValues.nombreProducto}
        />
        <br />
        <br />
        <div style={{ width: 1500 }}>
          <input onChange={changeEquipoHandler} type="file" name="file" />
        </div>
        <label>Adjuntar imagen del equipo a financiar (Opcional)</label>
      </div>

      {/* <div>
        <InputLabel sx={{ marginTop: 3 }}>Productos Disponibles</InputLabel>
        <Select
          sx={{ minWidth: "100%" }}
          labelId="productosLbl"
          id="productos"
          value={selectedProducto}
          onChange={handleProductoChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300, // You can adjust this value as needed
              },
            },
          }}
        >
          <MenuItem value={0}>{"- No Aplica -"}</MenuItem>
          {productos.map((product) => (
            <MenuItem value={product.Id}>{product.Description}</MenuItem>
          ))}
        </Select>
      </div> */}
      <div>
        <InputLabel sx={{ marginTop: 3 }} id="sucursal">
          Sucursal que desea retirar el equipo
        </InputLabel>
        <Select
          sx={{ minWidth: "100%" }}
          labelId="sucursal"
          id="sucursal"
          value={sucursal}
          label="Age"
          onChange={handleSucursalChange}
          required
        >
          <MenuItem value={"1"} selected={true}>
            (Don Bosco), Prox. a 27 de febrero
          </MenuItem>
          <MenuItem value={"2"} selected>
            Autopista San Isidro
          </MenuItem>
        </Select>
        <label hidden={sucursal.toString() !== ""} style={{ color: "#d32f2f" }}>
          Debe seleccionar este campo
        </label>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default Cliente;
