import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

//import store from "./store";
// import Home from "./pages/Home";
// import About from "./pages/About";
import "./App.css";
import Header from "./components/header";
import Home from "./components/home";

function App() {
  return (
    <Router>
      <div>
        <Header />
      </div>
      <div>
        <Home />
      </div>
    </Router>
  );
}

export default App;
