import { TextField } from "@mui/material";
import InputMask from "react-input-mask";
import React, { useState } from "react";

function PhoneFamiliarInput(props) {
  return (
    <InputMask
      mask="999-999-9999"
      type="number"
      inputProps={{ pattern: "[0-9]*" }}
      value={props.value}
      onChange={props.onChange}
    >
      {() => (
        <TextField
          sx={{ marginTop: 3 }}
          label="Telefono del Familiar"
          name="telefonoFamiliar"
          variant="standard"
          fullWidth
          inputProps={{ pattern: "[0-9]*" }}
          required
          error={!props.value}
          helperText="Enter a valid phone number"
          value={props.value}
          onChange={props.onChange}
        />
      )}
    </InputMask>
  );
}
function Referencia({ formValues, handleFormChange }) {
  const handleInputTelFamiliar = ({ target: { value } }) => {
    setPhone(value);
    formValues.telefonoFamiliar = value;
  };
  const [phone, setPhone] = useState("");
  return (
    <div style={{ margin: 15 }}>
      <TextField
        sx={{ marginTop: 3 }}
        label="Nombre de un Familiar"
        name="nombreFamiliar"
        variant="standard"
        value={formValues.nombreFamiliar}
        onChange={handleFormChange}
        fullWidth
        required
        error={!formValues.nombreFamiliar}
        helperText={formValues.nombreFamiliar === "" ? "Debe llenar este campo" : null}
      />
      {/* <TextField
        sx={{ marginTop: 3, width: "100%" }}
        label="Telefono del Familiar"
        name="telefonoFamiliar"
        variant="standard"
        value={formValues.telefonoFamiliar}
        onChange={handleFormChange}
        fullWidth
        type="number"
        inputProps={{ pattern: "[0-9]*" }}
        required
        error={!formValues.telefonoFamiliar}
        helperText={formValues.telefonoFamiliar === "" ? "Debe llenar este campo" : null}
      /> */}

      <PhoneFamiliarInput
        error={!formValues.telefonoFamiliar}
        helperText={formValues.telefonoCelular === "" ? "Debe llenar este campo" : null}
        value={phone}
        required
        onChange={handleInputTelFamiliar}
      />
    </div>
  );
}

export default Referencia;
